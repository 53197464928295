<template>
  <el-drawer    
    :visible="open"
    :with-header="false"
    :before-close="handleClose"
    direction="rtl"
    size="33%"
    append-to-body
    @open="inOpen"
    @opened="afterOpen"
    @closed="afterLeave"
    class="drawer"
  >
    <div class="clents-blocks">
      <div class="title-modal">
        <div class="ml-20 flex1" style="color: black;" @click="$emit('drawer:close', false)">
          <i class="el-icon-arrow-left"></i>
          {{ client ? client.name : '' }}
        </div>
        <div class="flex1" style="color: black; font-size: 24px;">          
          {{ totalNotPaidOfSelected }}
        </div>
        <el-button
          type="success"
          class="bg-success"
          style="margin-right: 1vw"
          @click="paymentByDefauilt"
        >
        {{ $t('pay') }}
        </el-button>
      </div>

      <div v-loading="is_loading">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>
                <label class="form-checkbox">
                  <input v-model="selectAll" type="checkbox" @click="select" />
                  <i class="form-icon"></i>
                </label>
              </th>
              <th>{{ $t('application') }}</th>
              <th>{{ $t('totalPrice') }}</th>
              <th>{{ $t('paid_price') }}</th>
              <th>{{ $t('not_paid') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders" :key="order.id">
              <td>
                <label class="form-checkbox">
                  <input
                    v-model="selected"
                    type="checkbox"
                    :value="order.id"                    
                  />
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ order.id }}</td>
              <td>{{ order.total_price }}</td>
              <td>{{ order.paid_price }}</td>
              <td>{{ order.not_paid }}</td>              
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <payment
      :open="paymentDrawerByDefauilt"
      :client="client"
      :debt="totalNotPaidOfSelected"
      :is_by_default="false"
      :selected_order_ids="selected"
      @drawer:close="closePaymentDrawer"      
      />
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import ClientApi from "@/api/Client";
import VueTypes from "vue-types";
import payment from "./payment";
import { includes } from "lodash";

export default {  
  props: {
    open: VueTypes.bool.isRequired,
    client: {
      default: null
    },    
  },
  components: {
    payment    
  },
  data() {
    return {
      paymentDrawerByDefauilt: false,
      is_loading: false,
      selected: [],
      selectAll: false,
      orders: []
    };
  },
  watch: {
    selected: {
      deep: true,
      handler(newVal, oldVal) {
        if (
          this.orders.length > 0 &&
          this.orders.length === newVal.length
        ) {
          this.selectAll = true;
        } else {
          this.selectAll = false;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      
    }),
    totalNotPaidOfSelected() {
      let total = 0;
      this.orders.forEach(item => {
        if (includes(this.selected, item.id)) {
          total += item.not_paid;
        }
      })

      return total;
    }
  },
  created() {
    
  },
  methods: {
    inOpen() {
      this.getOrders();
    },
    afterOpen() {
      this.selected = [];
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.orders) {
          this.selected.push(this.orders[i].id);
        }
      }
    },
    getOrders() {
      this.orders = [];

      ClientApi.getDebtorOrders(this.client ? this.client.id : null)
        .then(res => {
          const { result } = res.data;          
          this.orders = result.data;
          this.is_loading = false;          
        })
        .catch(err => {
          this.is_loading = false;
        });
    },    
    paymentByDefauilt() {      
      this.paymentDrawerByDefauilt = true;
    },
    closePaymentDrawer() {
       this.paymentDrawerByDefauilt = false;
       return this.$emit("drawer:close", false);
    },
    handleClose(done) {      
      this.selected = [];
      done();
      return this.$emit("drawer:close", false);
    },
    afterLeave() {
      
    }
  }
};
</script>

<style scoped>
.notify_title {
  color: black;
  text-align: center;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
  color: black;
}
.table th {
  border-bottom-width: 0.1rem;
}

.table td,
.table th {
  border-bottom: 0.05rem solid #dadee4;
  padding: 0.6rem 0.4rem;
}
.table.table-hover tbody tr:hover {
  background: #eef0f3;
}
.form-checkbox,
.form-radio,
.form-switch {
  display: block;
  line-height: 1.2rem;
  margin: 0.2rem 0;
  min-height: 1.4rem;
  padding: 0.1rem 0.4rem 0.1rem 1.2rem;
  position: relative;
}
</style>

